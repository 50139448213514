
$labelOffsetStart: -90px;
$labelOffsetEnd:   -70px;


@-webkit-keyframes fadeSmooth {
    from {
        opacity: 0;
        left: $labelOffsetStart;
    }

    to {
        opacity: 1;
        left: $labelOffsetEnd;
    }
}
@keyframes fadeSmooth {
    from {
        opacity: 0;
        left: $labelOffsetStart;
    }

    to {
        opacity: 1;
        left: $labelOffsetEnd;
    }
}
                                   
.fadeIn {
}


.table-routes {
  tr {
    position: relative; // anchor
  }

  td {
    color: #337ab7;
    text-decoration: none;

    span:hover {
      text-decoration: underline;
    }

    span {
        cursor: pointer;
    }

  }


  .noexport-reason,
  .reject-reason {
    font-size: 90%;
    color: #333;
    margin-bottom: 1px;

    a {
      color: #aa0000;
    }
  }

  .col-route-flags {
    // padding: 7px 0px;
    padding-left: 2px;
    width: auto;

    white-space: nowrap;

    i {
      font-style: normal;
    }
  }

  .col-route-network {
    // padding: 8px 0px;
    // padding-left: 3px;

  }

  .route-prefix-flags {
    .primary-route {
       color: #efb208; 
    }

    .rpki-valid {
       color: #009900;
    }
    
    .rpki-invalid {
       color: #d80000;
    }

    .rpki-unknown {
       color: #2e6da4;
    }

    .rpki-not-checked {
       color: #2e6da4;
    }

    .reject-candidate-route {
       color: #d80000;
    }
  }

  .route-prefix-flags {
  }

  .route-prefix-flag {
    color: #555;
    text-decoration: none;
    border: none;

    font-size: 10px;

    cursor: default;

    padding-left: 3px;

    div {
        font-size: 12px;
        display: none;
        // top: -5px;
        left: $labelOffsetStart;
        background: rgba(0, 0, 0, 0.45);
        color: white;
        position: absolute;
        padding: 5px;
        margin-top: -22px;
        border-radius: 3px;
        font-weight: bold;

        -webkit-animation-name: fadeSmooth;
        animation-name: fadeSmooth;
    }

    &:hover {
        text-decoration: none;
        div {
            display: block;

            -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
        }
    }

  }

  .route-network {
    display: inline-block;
    position: relative;
    width: 100%;
    white-space: nowrap;
  }

  .route-age {
    white-space: nowrap;
  }
}


.table-protocols {
  .date-since {
    white-space: nowrap;
  }
  background: white;
}


.routes-loading.card {
    p {
        font-family: monospace;
        font-size: 12px;
    }
}

.routes-header-container {
    text-decoration: none;

    a:hover {
        text-decoration: none;
    }
}


.routes-quick-links {
    margin: -0.75em 0; 

    text-align: right;
    span {
      color: #888;
    }

    ul {
        padding: 0px;
        margin: 0px;
        display: inline-block;
    }
    li {
        display: inline-block;
        padding: 0px 4px;
        margin: 0px 0px 0px 8px;
        background: white;
        border-radius: 3px;

        &.filtered a {
          color: orange;
        }

        &.received a {
          color: green;
        }

        &.not-exported a {
          color: red;
        }
    }

}


/*
 * Related Peers
 */
.related-peers {
    $tabHeight: 23px;
    $shadowOffset: 3px;

    display: block;
    position: absolute; 
    overflow: hidden;

    padding: $shadowOffset $shadowOffset 0px $shadowOffset;
    margin: 0px;
    
    list-style: none;

    top: -$tabHeight - $shadowOffset;

    li {
        height: $tabHeight;
        line-height: $tabHeight;

        display: inline-block;

        padding: 0px 5px;
        margin: 0px 10px 0px 0px;
        
        border-bottom: 1px solid #ffffff;
        background: #ffffff;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;

        box-shadow: 0px 1px 3px #aaa;

        &.active {
            font-weight: bold;
            a {
                color: #222;
            }
        }

        a, a:hover {
            text-decoration: none;     
        }
    }
}

.has-related-peers {
    .details-main {
        padding-top: 21px;
    }
}

.card-header-routes {
    &.received {
        color: green;
    }
    &.filtered {
        color: orange;
    }
    &.not-exported {
        color: red;
    }
}

.routes-view {
    &.routes-filtered {
        .route-prefix-flag {
            &.reject-candidate-route {
                display: none;
            }
        }
    }

    &.routes-received {
        .table-routes {
            .reject-reason {
            }
        }
    }
}

// Responsive tweaks: The awkward inbetween.
@media (min-width: 1200px) and (max-width: 1258px) {
  .details-main {
    > .col-main {
        width: 80%;
        float: left;
    }
    > .col-aside-details {
        width: 20%;
        float: left;
    }
  }
}

// Related Peers Box
.card-related-peers {
    h2 {
        color: #555;
        font-weight: bold;

        margin-top: 8px;
        margin-bottom: 5px;

        font-size: 12px;

        text-transform: uppercase;

        padding: 0;
    }

    h3 {
        font-size: 14px;
        margin: 10px 0px 0px 0px;
        padding-bottom: 2px;
        border-bottom: 1px solid #ccc;
        color: black;
    }

    .related-peers-rs-peer {
        margin: 5px 0px;
        width: 100%;
        td {
        }
    }
}


.card-related-peers {
    table {
        width: 100%;
    }
    .uptime {
        text-align: right;
    }
    .peer-stats {
        text-align: center;
        cursor: default;
        .routes-received {
            color: green;
        }
        .routes-accepted {
            color: green;
        }
        .routes-filtered {
            color: orange;
        }
        .routes-exported {
            
        }
    }
}


.atooltip {
    position: relative;
    display: inline-block;
    cursor: default;
}
.atooltip i {
    position: absolute;
    font-style: normal;
    width:140px;
    color: #ffffff;
    background: #000000;
    height: 30px;
    line-height: 30px;
    text-align: center;
    visibility: hidden;
    border-radius: 6px;
}
.atooltip i:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0; height: 0;
    border-top: 8px solid #000000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
}
.atooltip:hover i {
    visibility: visible;
    opacity: 0.8;
    bottom: 30px;
    left: 50%;
    margin-left: -76px;
    z-index: 999;
}


