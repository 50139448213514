
.lookup-container {
    h2 {
        text-transform: uppercase;
        color: #337ab7;
        font-size: 14px;
        line-height: 1;
        margin-top: 0px;
    }
}


.lookup-result-summary {
    ul {
      list-style: none;
      padding: 0px;
      margin: 0px;
    }

    li {
      padding: 5px 0;
    }
}


.lookup-help {
    margin: 30px 20px;

    h3 {
        font-weight: normal;
        font-style: italic;
        color: #337ab7;
        margin-top: 15px;
        font-size: 14px;
        text-transform: uppercase;
    }

    b {

    }
}

.lookup-examples {
  
  > ul {
    display: flex;
    list-style: none;
    padding: 0px;
    margin: 0px;
    width: 50%;
    flex-wrap: wrap;

    li {
      flex: 1;
      padding: 0px;

      .label {
        display: flex;
        margin: 5px;
        line-height: inherit;
        font-size: 1.4rem;
        box-shadow: 0 1px 1px rgba(0,0,0,.2);
      }

      .label-default {
        background-color: #ffffff;
        color: #222;
      }

      a {
        text-decoration: none;
      }
    }
  }
}

